import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class OriginationService {
  private url: string = "https://torp.tremend.ro";

  private token: string;

  createHeader = {
    headers: new HttpHeaders().set('shouldSentRealSms', "true")
  }

  generalHeader = {
    headers: new HttpHeaders().set('Authorization', `Bearer ${this.getToken()}`)
  }

  constructor(private httpClient: HttpClient) { }

  getToken(): string {
    if (!!localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user')).authToken
    }
    return "";
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).hasEnrolment == true;
  }

  logOut() {
    localStorage.removeItem('user');
  }

  createOtp(msisdn): Observable<any> {
    return this.httpClient.get(`${this.url}/otp/${msisdn}`, this.createHeader)
      .pipe(catchError(this.errorHandler));
  }

  checkOtp(code, msisdn): Observable<any> {
    return this.httpClient.post(`${this.url}/otp/check`, {
      "code": code,
      "phone": msisdn
    }, this.generalHeader).pipe(
      catchError(this.errorHandler),
      tap(val => {
        localStorage.setItem('user', JSON.stringify(val));
      })
    );
  }

  enrollmentInfo(firstName, lastName, cnp, address): Observable<any> {
    return this.httpClient.post(`${this.url}/origination-api/enrollment/info`, {
      "firstName": firstName,
      "lastName": lastName,
      "cnp": cnp,
      "address": address
    }, this.generalHeader).pipe(catchError(this.errorHandler));
  }

  saveSignature(key, deviceRegistrationId): Observable<any> {
    return this.httpClient.post(`${this.url}/origination-api/enrollment/signature`, {
      "publicKey": key,
      "deviceRegistrationId": deviceRegistrationId,
      "devicePlatform": "web"
    }, this.generalHeader).pipe(catchError(this.errorHandler));
  }

  history(): Observable<any> {
    return this.httpClient.get(`${this.url}/origination-api/audit/list`, this.generalHeader).pipe(catchError(this.errorHandler));
  }

  documents(): Observable<any> {
    return this.httpClient.get(`${this.url}/origination-api/audit/list-documents`, this.generalHeader).pipe(catchError(this.errorHandler));
  }

  authorize(actionId, timestamp, signature): Observable<any> {
    return this.httpClient.post(`${this.url}/origination-api/action/authorize`, {
      "payload": {
        "actionId": actionId,
        "timestamp": timestamp
      },
      "signature": signature
    }, this.generalHeader).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
}
