import { Component, OnInit, ViewChild } from '@angular/core';
import { ElectronicIdService } from '../../@core/services/electronic-id.service'
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NbStepperComponent, NbTabsetComponent, NbTabComponent, NbToastrService, NbComponentStatus } from '@nebular/theme';
import { OriginationService } from '../../@core/services/origination.service';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';

declare var EID: any;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('stepper', null) stepper: NbStepperComponent;
  @ViewChild('tabset', null) tabset: NbTabsetComponent;

  @ViewChild('tab1', null) tab1: NbTabComponent;
  @ViewChild('tab2', null) tab2: NbTabComponent;
  @ViewChild('tab3', null) tab3: NbTabComponent;

  firstForm: FormGroup;
  secondForm: FormGroup;

  loading = false;
  phoneNumber: string;
  confirmationCode: string;
  videoToken: string;
  videoId = EID.videoId("#video", {
    lang: "en"
  });

  firstname: string;
  lastname: string;
  cnp: string;
  address: string;

  constructor(
    private electronicService: ElectronicIdService,
    private originationService: OriginationService,
    private toastrService: NbToastrService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    // Phone form
    this.firstForm = this.fb.group({
      tel_1: ['', Validators.required],
      tel_2: ['', Validators.required],
      tel_3: ['', Validators.required],
      tel_4: ['', Validators.required],
      tel_5: ['', Validators.required],
      tel_6: ['', Validators.required],
      tel_7: ['', Validators.required],
      tel_8: ['', Validators.required],
      tel_9: ['', Validators.required],
      tel_10: ['', Validators.required],
    });

    // Code validation form
    this.secondForm = this.fb.group({
      digit_1: ['', Validators.required],
      digit_2: ['', Validators.required],
      digit_3: ['', Validators.required],
      digit_4: ['', Validators.required],
    })

    // On ID and Face scan handler
    this.videoId.on("completed", (video) => {
      this.videoId.turnOff();
      this.loading = true;
      this.electronicService.videoInfo(video.id).subscribe(resp => {

        this.lastname = resp.document.subject.secondaryName
        this.firstname = resp.document.subject.primaryName
        this.cnp = resp.document.subject.personalNumber
        this.address = resp.document.subject.address

        this.loading = false;
        this.stepper.next();
      });
    });

    // Sometimes errors pop but the video is working ... that is why is not handled
    this.videoId.on("failed", (error) => {
      // console.log("VideoId Failed");
    });
  }

  // Phone cand code logic to jump from input to input
  onKeydown(event) {
    let element = event.srcElement;

    if (event.key == "Backspace")
      return;
    else
      setTimeout(() => {
        try {
          element.nextElementSibling.focus();
        } catch (err) {
          console.log("End of inputs")
        }
      }, 0);
  }

  // Start video to confirm user's identity and get data from OCR
  setupVideo(token: string) {
    this.videoId.turnOn();
    this.videoId.start({
      authorization: token,
      idType: 210
    });
  }

  // First form logic
  onFirstSubmit() {
    if (this.firstForm.invalid) {
      return;
    }

    this.phoneNumber = Object.keys(this.firstForm.value).map(key => this.firstForm.value[key]).join("");

    this.loading = true;
    this.originationService.createOtp(`+4${this.phoneNumber}`)
      .subscribe(
        res => {
          this.loading = false;
          this.tabset.selectTab(this.tab2);
        },
        error => {
          console.log(error);
          this.loading = false;
          this.toastrService.show("", error, { status: "danger" });
        });
  }

  // Second form logic
  onSecondSubmit() {
    if (this.secondForm.invalid) {
      return;
    }

    this.confirmationCode = Object.keys(this.secondForm.value).map(key => this.secondForm.value[key]).join("");

    this.loading = true;
    this.originationService.checkOtp(this.confirmationCode, `+4${this.phoneNumber}`).subscribe(
      res => {
        this.loading = false;
        if (res.hasEnrollment) {
          this.router.navigate(['dashboard'])
        } else {
          this.tabset.selectTab(this.tab3);
        }
      },
      error => {
        this.loading = false;
        this.toastrService.show("", error, { status: "danger" });
      });
  }

  // Request id to perform identity check
  onScanDocuments() {
    this.electronicService.requestId().subscribe(
      resp => this.setupVideo(resp.authorization),
      error => {
        this.loading = false;
        this.toastrService.show("", error, { status: "danger" });
      });
  }

  // Finish registration
  onFinishRegistration() {
    this.loading = true;
    this.originationService.enrollmentInfo(
      this.firstname,
      this.lastname,
      this.cnp,
      this.address
    ).subscribe(
      resp => {
        this.loading = false;
        this.stepper.next();
      },
      error => {
        this.loading = false;
        this.toastrService.show("", "Inrolarea nu a fost inițiată. Va rugam contactați Banca", { status: "info" });
        this.router.navigate(['login'])
      })
  }
}
