import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElectronicIdService {
  private url: string = "https://etrust-sandbox.electronicid.eu/v2";

  header = {
    headers: new HttpHeaders().set('Authorization', "Bearer c92eb28c-da65-4b3b-ad5d-57a346eb0bc7")
  }

  constructor(private httpClient: HttpClient) {

  }

  requestId(): Observable<any> {
    return this.httpClient.post(`${this.url}/videoid.request`, { process: "Unattended" }, this.header);
  }

  videoInfo(videoId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/videoid/${videoId}`, this.header);
  }
}
